<style>
/* CSS para quitar el borde superior de la tabla */
.no-border-top th,
.no-border-top td {
  border-top: none !important;
}
</style>

<template>
  <!-- INSPECCIONAR BOTIQUINES -->

  <card>
    <form @submit.prevent="saveDataBotis()" class="p-4">
      <!-- ------------------------- -->
      <h4 class=""><b> INSPECCIÓN DE BOTIQUINES</b></h4>
      <div class="row p-4">
        <div class="col-6">
          <label for="sucursal">Sucursal</label><br />
          <b-form-select
            id="sucursal"
            v-model="InspeccionBotiquinesPregunta.SucursalBotiquinId"
            value-field="id"
            text-field="descripcion"
            :options="listaSucursales"
            required
          ></b-form-select>
        </div>
        <div class="col-6">
          <div>
            <label for="persona" class="form-label">Piso</label>
            <input type="text" list="pisos" v-model="InspeccionBotiquinesPregunta.PisoBotiquin" class="form-control" required />
            <datalist id="pisos">
              <option v-for="pisos in pisoInspeccionFilter" :value="pisos.pisoInspeccion" :key="pisos.pisoInspeccion">
                {{ pisos.pisoInspeccion }}
              </option>
            </datalist>
          </div>
        </div>
        <div class="col-6 py-2">
          <div>
            <label for="evaluador" class="form-label">Evaluador</label>
            <b-form-select
              id="evaluador"
              @input="getPersonaUbicacion(InspeccionBotiquinesPregunta.PersonaInspeccionId)"
              v-model="InspeccionBotiquinesPregunta.PersonaInspeccionId"
              value-field="id"
              text-field="nombres"
              :options="NombresPersonas"
              required
            >
            </b-form-select>
          </div>
        </div>
        <div class="col-6 py-2">
          <div>
            <label for="evaluadorCargo" class="form-label">Cargo Evaluador</label>
            <input v-model="CargoNombre" placeholder="" type="text" class="form-control" id="fecha" disabled />
          </div>
        </div>
        <div class="col-6 py-2">
          <div>
            <label for="fecha" class="form-label">Fecha Evaluación</label>
            <input @change="DateValidate" v-model="InspeccionBotiquinesPregunta.FechaInspeccion" placeholder="" type="date" class="form-control" id="fecha" required />
          </div>
        </div>

        <div class="col-6 py-2">
          <div>
            <label for="fecha" class="form-label">Fecha Próxima Evaluación</label>
            <input v-model="InspeccionBotiquinesPregunta.FechaSigInspeccion" placeholder="" type="date" class="form-control" id="fecha" :min="minDate" required />
          </div>
        </div>
        <div class="col-6 py-2">
          <div>
            <label for="evaluador" class="form-label">Responsable del botiquin</label>
            <b-form-select
              id="evaluador"
              v-model="InspeccionBotiquinesPregunta.PersonaResponsableId"
              value-field="id"
              text-field="nombres"
              :options="NombresPersonas"
              required
            ></b-form-select>
          </div>
        </div>
      </div>

      <div class="text-left p-4">
        <div>
          <h4 class="pb-4"><b> REGISTRO INSPECCIÓN DE BOTIQUINES</b></h4>
          <table class="table table-striped no-border-top">
            <thead class="h4">
              <tr>
                <th class="col-2">Elemento</th>
                <th class="col-1">Existencia</th>
                <th class="col-1">Entregado</th>
                <th class="col-1">Cant Entregada</th>
                <th class="col-2">Fecha de Vencimiento</th>
                <th>Descripción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in BotiquinesItems" :key="item.item">
                <td style="text-align: left">
                  {{ item.item }}
                </td>
                <td>
                  <input v-model="respuestas[item.item].CantidadExistente" type="number" class="form-control pb-2" id="CantidadExistentexd" min="0" />
                </td>
                <td class="d-flex" style="justify-content: center">
                  <CheckButton v-model="respuestas[item.item].EntregaItem" />
                </td>
                <td>
                  <input v-model="respuestas[item.item].CantidadEntregada" type="number" class="form-control" id="CantidadEntregada" min="0" />
                </td>
                <td>
                  <input v-model="respuestas[item.item].FechaVencimiento" type="date" class="form-control" id="FechaVencimiento" required />
                </td>
                <td>
                  <textarea v-model="respuestas[item.item].ObservacionItem" type="textarea" class="form-control" id="ObservacionItem" placeholder="Introduce una descripción" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center">
          <button
            type="submit"
            class="btn btn-primary vs-button vs-button--null vs-button--size-null vs-button--animate vs-button--success vs-button--gradient"
            style="font-size: 15px; width: 15%; height: 50px"
          >
            <i class="fas fa-plus mr-1"></i> Completar Registro
          </button>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
import CheckButton from "../../../../components/buttons/CheckButton.vue";
import { mapGetters } from "vuex";
import { BotiquinesItems } from "./inspeccionElementos";
const Swal = require("sweetalert2");
export default {
  components: {
    CheckButton,
  },

  data() {
    return {
      BotiquinesItems,
      usuario: {},
      listaSucursales: [],
      listaPersonas: [],
      listaPreguntas: [],
      listaPisosElementos: [],
      listaPersonaUbicacion: [],
      minDate: null,
      InspeccionBotiquinesPregunta: {
        FechaInspeccion: null,
        FechaSigInspeccion: null,
        PersonaInspeccionId: null,
        CargoPersonaInspeccionId: null,
        PersonaResponsableId: null,
        PisoBotiquin: null,
        SucursalBotiquinId: null,
      },
      respuestas: {},
      CargoNombre: null,
    };
  },

  async mounted() {
    await this.initializeInspeccionBotiquinesRespuesta();
    this.$isLoading(true);

    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    //console.log("ussssssssssss", this.usuario);

    await this.getSucursales();
    await this.getPersonas();
    await this.getPisos();

    this.$isLoading(false);
    //await this.getData();
  },
  computed: {
    ...mapGetters(["userLoggedIn"]),

    NombresPersonas() {
      //console.log(this.listaPersonas)
      return this.listaPersonas.map((persona) => ({
        ...persona,
        id: persona.id,
        nombres: `${persona.nombres} ${persona.apellidos}`,
      }));
    },

    pisoInspeccionFilter() {
      const seen = new Set();
      return this.listaPisosElementos.filter((item) => {
        const isDuplicate = seen.has(item.pisoInspeccion);
        seen.add(item.pisoInspeccion);
        return !isDuplicate;
      });
    },
  },
  methods: {
    async initializeInspeccionBotiquinesRespuesta() {
      // Inicializar InspeccionBotiquinesRespuesta basado en BotiquinesItems
      await this.BotiquinesItems.forEach((item) => {
        this.$set(this.respuestas, item.item, {
          CantidadExistente: "",
          EntregaItem: false,
          CantidadEntregada: "",
          FechaVencimiento: "",
          ObservacionItem: "",
        });
      });
    },

    async getPersonas() {
      //console.log("usuario------>", this.usuario);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/ListPersonaByEmpresa/" + this.usuario.empresa.id,
        });
        //console.log("EL ID DE LA EMPRESA ES:::::::::::::::", this.usuario.empresa.id);
        //console.log("get Personas... ", res);
        if (res.length > 0) {
          this.listaPersonas = res;
        } else {
          this.listaPersonas = [];
        }
      } catch (error) {
        this.listaPersonas = [];
        console.log("err", error);
      }
    },

    async getSucursales() {
      //console.log("Sucursales------>", this.usuario);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Sucursal/ListSucursalEmpresa/" + this.usuario.empresa.id,
        });
        //console.log("get sucursales... ", res);
        if (res.length > 0) {
          this.listaSucursales = res;
        } else {
          this.listaSucursales = [];
        }
      } catch (error) {
        this.listaSucursales = [];
        console.log("err", error);
      }
    },

    async getPisos() {
      //console.log("pisos------>", this.listaPisosElementos);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "SstInspeccion/GetSstInspeccionElementos/",
        });

        // console.log("get pisoooos... ", res);
        if (res.length > 0) {
          this.listaPisosElementos = res;
        } else {
          this.listaPisosElementos = [];
        }
      } catch (error) {
        this.listaPisosElementos = [];
        console.log("err", error);
      }
    },

    async getPersonaUbicacion() {
      try {
        //console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/GetByPersona/" + this.InspeccionBotiquinesPregunta.PersonaInspeccionId,
        });

        let cargo = res.cargo;
        //console.log("getPersonaUbicacion... ", cargo);
        if (cargo) {
          this.InspeccionBotiquinesPregunta.CargoPersonaInspeccionId = cargo.id;
          this.CargoNombre = cargo.nombre;
          this.listaPersonaUbicacion = cargo;

          //console.log("LISTADO DE CARGOS DE LA EMPRESempresasAAAAAAAAAAAAAAAA...", cargo.nombre);
        } else {
          console.log("VACIO", this.listaPersonaUbicacion);
          this.listaPersonaUbicacion = [];
        }
      } catch (error) {
        this.listaPersonaUbicacion = [];
        console.log("err", error);
      }
    },

    async saveDataBotis() {
      try {
        let respuestaInspeccionSucursal = this.InspeccionBotiquinesPregunta;
        console.log("save data botis", respuestaInspeccionSucursal);

        let res = await this.$store.dispatch("hl_post", {
          path: "SstInspeccion/CreateSstInspeccionBotiquin/",
          data: respuestaInspeccionSucursal,
        });

        let IdInspeccion = res.id;

        let respuestaInspeccionBotiquines = this.respuestas;
        console.log("Guardar respuesta botiquines", respuestaInspeccionBotiquines);

        Object.keys(respuestaInspeccionBotiquines).forEach((item) => {
          const itemPregunta = respuestaInspeccionBotiquines[item];
          const dataRespuestaBotiquin = {
            SstInspeccionId: IdInspeccion,
            cantidadExistente: itemPregunta.CantidadExistente == "" ? 0 : itemPregunta.CantidadExistente,
            entregaItem: itemPregunta.EntregaItem ? "Entregado" : "No entregado",
            cantidadEntregada: itemPregunta.CantidadEntregada == "" ? 0 : itemPregunta.CantidadEntregada,
            fechaVencimiento: itemPregunta.FechaVencimiento,
            observacionItem: itemPregunta.ObservacionItem == "" ? "Sin observaciones" : itemPregunta.ObservacionItem,
            sstElementoBotiquin: item,
          };

          this.$store.dispatch("hl_post", {
            path: "SstInspeccion/CreateSstInspeccionBotiquinCantidades/",
            data: dataRespuestaBotiquin,
          });

          console.log("ESTO LE VOY A PASAR AL POST", dataRespuestaBotiquin);
        });

        if (res) {
          Swal.fire({
            title: "Listo!",
            text: "Registro almacenado correctamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$router.push("/helex/SGSST/InspeccionElementos");
            }
          });
        }
      } catch (e) {
        console.log("err", e);
      }
    },
    DateValidate() {
      this.minDate = this.InspeccionBotiquinesPregunta.FechaInspeccion;
    },
  },
};
</script>
