<style>
.borders-table th,
.borders-table tr {
  border: 1px solid !important;
  padding: 5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important
}






</style>

<template>
  <card>
    <form @submit.prevent="saveDataExtintores()" class=" p-4">
      <!-- --------------------------- -->
      <!-- ------------------------- -->
      <h4 class=""><b> INSPECCIÓN DE EXTINTOR</b></h4>
      <div class="row p-4">

        <div class="col-6">
          <label for="sucursal">Sucursal</label><br />
          <b-form-select id="sucursal" v-model="InspeccionExtintorSucursal.SucursalId" value-field="id"
            text-field="descripcion" :options="listaSucursales" required></b-form-select>
        </div>
        <div class="col-3">
          <div>
            <label for="fecha" class="form-label">Fecha Evaluación</label>
            <input @change="DateValidate" v-model="InspeccionExtintorSucursal.FechaInspeccionExtintor" placeholder=""
              type="date" class="form-control " id="fecha" required />
          </div>
        </div>
        <div class="col-3">
          <div>
            <label for="fecha" class="form-label">Fecha Próxima Evaluación</label>
            <input v-model="InspeccionExtintorSucursal.FechaSigInspeccionExtintor" placeholder="" type="date"
              class="form-control " id="fecha" :min="minDate" required />
          </div>
        </div>
        <div class="col-6 py-2">
          <div>
            <label for="evaluadorGSST" class="form-label">Evaluador GSST</label>
            <b-form-select id="evaluadorGSST"
              @input="getPersonaUbicacion('Input1', InspeccionExtintorSucursal.PersonaInspeccionGSSTId)"
              v-model="InspeccionExtintorSucursal.PersonaInspeccionGSSTId" value-field="id" text-field="nombres"
              :options="NombresPersonas" required>
            </b-form-select>
          </div>
        </div>
        <div class="col-6 py-2">
          <div>
            <label for="evaluadorGSST" class="form-label">Cargo Evaluador GSST</label>
            <input v-model="CargoNombreGSST" placeholder="" type="text" class="form-control " disabled />
          </div>
        </div>
        <div class="col-6 py-2">
          <div>
            <label for="evaluadorCOPASST" class="form-label">Evaluador COPASST</label>
            <b-form-select id="evaluadorCOPASST"
              @input="getPersonaUbicacion('Input2', InspeccionExtintorSucursal.PersonaInspeccionCOPASSTId)"
              v-model="InspeccionExtintorSucursal.PersonaInspeccionCOPASSTId" value-field="id" text-field="nombres"
              :options="NombresPersonas" required>
            </b-form-select>
          </div>
        </div>
        <div class="col-6 py-2">
          <div>
            <label for="evaluadorCOPASST" class="form-label">Cargo Evaluador COPASST</label>
            <input v-model="CargoNombreCOPASST" placeholder="" type="text" class="form-control " id="evaluadorCOPASST"
              disabled />
          </div>
        </div>
        <div class="col-6 py-2">
          <div>
            <label for="evaluador" class="form-label">Evaluador COPASST (Opcional)</label>
            <b-form-select id="evaluadorCOPASSTDos"
              @input="getPersonaUbicacion('Input3', InspeccionExtintorSucursal.PersonaInspeccionCOPASSTDosId)"
              v-model="InspeccionExtintorSucursal.PersonaInspeccionCOPASSTDosId" value-field="id" text-field="nombres"
              :options="NombresPersonas">
            </b-form-select>
          </div>
        </div>
        <div class="col-6 py-2">
          <div>
            <label for="evaluadorCargo" class="form-label">Cargo Evaluador COPASST(Opcional)</label>
            <input v-model="CargoNombreCOPASSTDos" placeholder="" type="text" class="form-control " id="fecha"
              disabled />
          </div>
        </div>



      </div>
      <div class="text-left p-4">
        <div>
          <h4 class=" pb-4"><b> REGISTRO INSPECCIÓN DE BOTIQUINES</b></h4>
          <table class="table-responsive table-striped borders-table" style="text-align:center">
            <thead>
              <tr>
                <th rowspan="3" class=" p-5">CÓDIGO</th>
                <th rowspan="3" class=" p-5">UBICACIÓN DEL EXTINTOR (Edificio/Piso)</th>
                <th rowspan="3" class=" p-4">TIPO</th>
                <th rowspan="3">CAPACIDAD</th>
                <th colspan="14">REVISIÓN ESTADO GENERAL</th>
                <th rowspan="2" colspan="2">FECHA DE RECARGA</th>
                <th rowspan="3" class=" px-5">OBSERVACIONES</th>
              </tr>

              <tr>
                <th colspan="3">CILINDRO</th>
                <th rowspan="2">MANIJA DE TRANSPORTE</th>
                <th rowspan="2">MANIJA DE DISPARO</th>
                <th rowspan="2">PRESIÓN</th>
                <th rowspan="2">MANOMETRO</th>
                <th rowspan="2">BOQUILLA</th>
                <th rowspan="2">MANGUERA</th>
                <th rowspan="2">ARO ANILLO DE SEGURIDAD</th>
                <th rowspan="2">CORNETA</th>
                <th rowspan="2">SEÑALIZACIÓN</th>
                <th rowspan="2">SOPORTE DE COLGAR</th>
                <th rowspan="2">ACCESO AL EXTINTOR</th>

              </tr>
              <tr>
                <th>PINTURA</th>
                <th class=" p-3">ESTADO</th>
                <th>AUTOADHESIVO FECHA/TIPO</th>
                <th>ACTUAL</th>
                <th>PRÓXIMA</th>
              </tr>


            </thead>
            <tbody>

              <tr v-for="extintor in filas" :key="extintor.extintor">

                <td style="text-align: left;">
                  <input type="text" class="form-control " v-model="respuestas[extintor].CodigoExtintor"
                    placeholder="Código" />
                </td>
                <td>
                  <input type="text" class="form-control" v-model="respuestas[extintor].UbicacionExtintor"
                    placeholder="Ubicación" />
                </td>
                <td class="d-flex" style="justify-content: center;">
                  <b-form-select id="tipoExtintor" value-field="id" v-model="respuestas[extintor].TipoExtintor"
                    text-field="nombres" :options="opcionesTipoExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="capacidadExtintor" value-field="id"
                    v-model="respuestas[extintor].CapacidadExtintor" text-field="nombres"
                    :options="opcionesCapacidadExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="pinturaExtintor" value-field="id" v-model="respuestas[extintor].PinturaCilindro"
                    text-field="nombres" :options="opcionesRevisionExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="pinturaExtintor" value-field="id" v-model="respuestas[extintor].EstadoCilindro"
                    text-field="nombres" :options="opcionesRevisionExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="pinturaExtintor" value-field="id" v-model="respuestas[extintor].AutoAdhesivo"
                    text-field="nombres" :options="opcionesRevisionExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="pinturaExtintor" value-field="id" v-model="respuestas[extintor].ManijaTransporte"
                    text-field="nombres" :options="opcionesRevisionExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="pinturaExtintor" value-field="id" v-model="respuestas[extintor].ManijaDisparo"
                    text-field="nombres" :options="opcionesRevisionExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="pinturaExtintor" value-field="id" v-model="respuestas[extintor].Presion"
                    text-field="nombres" :options="opcionesRevisionExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="pinturaExtintor" value-field="id" v-model="respuestas[extintor].Manometro"
                    text-field="nombres" :options="opcionesRevisionExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="pinturaExtintor" value-field="id" v-model="respuestas[extintor].Boquilla"
                    text-field="nombres" :options="opcionesRevisionExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="pinturaExtintor" value-field="id" v-model="respuestas[extintor].Manguera"
                    text-field="nombres" :options="opcionesRevisionExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="pinturaExtintor" value-field="id" v-model="respuestas[extintor].AroSeguridad"
                    text-field="nombres" :options="opcionesRevisionExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="pinturaExtintor" value-field="id" v-model="respuestas[extintor].Corneta"
                    text-field="nombres" :options="opcionesRevisionExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="pinturaExtintor" value-field="id" v-model="respuestas[extintor].Senalizacion"
                    text-field="nombres" :options="opcionesRevisionExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="pinturaExtintor" value-field="id" v-model="respuestas[extintor].SoporteColgar"
                    text-field="nombres" :options="opcionesRevisionExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <b-form-select id="pinturaExtintor" value-field="id" v-model="respuestas[extintor].AccesoExtintor"
                    text-field="nombres" :options="opcionesRevisionExtintor" required>
                  </b-form-select>
                </td>
                <td>
                  <input type="date" class="form-control " v-model="respuestas[extintor].FechaRecargaExtintor" />
                </td>
                <td>
                  <input type="date" class="form-control " v-model="respuestas[extintor].FechaProximaRecargaExtintor" />
                </td>
                <td>
                  <input type="text" class="form-control " placeholder="Descripción"
                    v-model="respuestas[extintor].ObservacionExtintor" />
                </td>
              </tr>

            </tbody>
          </table>

        </div>
      </div>
      <div class=" d-flex justify-content-start">
        <vs-button type="button" icon relief success animation-type="rotate"  @click="addRow"> 
          <i class="fa-solid fa-fire-extinguisher" style="margin-right: 8px;"></i>
           Añadir
          <template #animate>
            <i class="fa-solid fa-plus" style="margin-right: 8px;"></i>
            Añadir
          </template>
        </vs-button>
        <vs-button type="button" icon relief danger animation-type="rotate"  @click="removeRow"> 
          <i class="fa-solid fa-fire-extinguisher" style="margin-right: 8px;"></i> 
          Remover
          <template #animate>
            <i class="fa-solid fa-minus" style="margin-right: 8px;"></i>
            Remover
          </template>
        </vs-button>
      </div>
      <div class=" d-flex justify-content-center">
        <button type="submit"
          class="btn btn-primary vs-button vs-button--null vs-button--size-null vs-button--animate vs-button--success vs-button--gradient"
          style="font-size: 15px; width: 15%; height: 50px;"><i class="fas fa-plus mr-1"></i> Completar
          Registro</button>
      </div>
    </form>
  </card>
</template>

<script>

import { mapGetters } from "vuex";


const Swal = require("sweetalert2");
export default {

  data() {
    return {

      usuario: {},
      listaSucursales: [],
      listaPersonas: [],
      listaPersonaUbicacion: [],
      minDate: "",
      filas: 1,

      InspeccionExtintorSucursal: {
        FechaInspeccionExtintor: null,
        FechaSigInspeccionExtintor: null,
        PersonaInspeccionGSSTId: null,
        CargoPersonaInspeccionGSSTId: null,
        PersonaInspeccionCOPASSTId: null,
        CargoPersonaInspeccionCOPASSTId: null,
        PersonaInspeccionCOPASSTDosId: null,
        CargoPersonaInspeccionCOPASSTDosId: null,
        SucursalId: null,
      },

      respuestas: {},
      CargoNombreGSST: null,
      CargoNombreCOPASST: null,
      CargoNombreCOPASSTDos: null,

      opcionesRevisionExtintor:
        [
          'C',
          'NC',
          'NA',
        ],

      opcionesTipoExtintor:
        [
          'ABC',
          'A',
          'B',
          'C',
          'D',
          'K'

        ],
      opcionesCapacidadExtintor: [

        '5Lb',
        '10Lb',
        '15Lb',
        '20Lb',
        '30Lb',

      ]

    }

  },

  async mounted() {

    await this.initializeInspeccionExtintoresRespuesta();
    this.$isLoading(true);

    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    //console.log("ussssssssssss", this.usuario);

    await this.getSucursales();
    await this.getPersonas();



    this.$isLoading(false);
    //await this.getData();

  },

  computed: {
    ...mapGetters(["userLoggedIn"]),

    NombresPersonas() {

      //console.log(this.listaPersonas)
      return this.listaPersonas.map(persona => ({
        ...persona,
        id: persona.id,
        nombres: `${persona.nombres} ${persona.apellidos}`,

      }));
    },



  },

  methods: {

    async initializeInspeccionExtintoresRespuesta() {
      // Crear un arreglo con los índices actuales de las filas
      const filasArray = Array.from({ length: this.filas }, (_, i) => i + 1);

      // Obtener las claves existentes en this.respuestas
      const existingKeys = Object.keys(this.respuestas);

      // Agregar o actualizar las filas necesarias
      filasArray.forEach(item => {
        if (!this.respuestas[item]) {
          // Si no existe la clave en this.respuestas, crear una nueva entrada
          this.$set(this.respuestas, item, {
            CodigoExtintor: '',
            UbicacionExtintor: '',
            TipoExtintor: '',
            CapacidadExtintor: '',
            PinturaCilindro: '',
            EstadoCilindro: '',
            AutoAdhesivo: '',
            ManijaTransporte: '',
            ManijaDisparo: '',
            Presion: '',
            Manometro: '',
            Boquilla: '',
            Manguera: '',
            AroSeguridad: '',
            Corneta: '',
            Senalizacion: '',
            SoporteColgar: '',
            AccesoExtintor: '',
            FechaRecargaExtintor: '',
            FechaProximaRecargaExtintor: '',
            ObservacionExtintor: '',
            SstInspeccionExtintorId: '',
          });
        }
      });

      // Eliminar las entradas que ya no son necesarias
      existingKeys.forEach(key => {
        if (!filasArray.includes(Number(key))) {
          // Eliminar la clave que no está en filasArray
          this.$delete(this.respuestas, key);
        }
      });
    },

    async getPersonas() {
      //console.log("usuario------>", this.usuario);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/ListPersonaByEmpresa/" + this.usuario.empresa.id,
        });
        //console.log("EL ID DE LA EMPRESA ES:::::::::::::::", this.usuario.empresa.id);
        //console.log("get Personas... ", res);
        if (res.length > 0) {
          this.listaPersonas = res;
        } else {
          this.listaPersonas = [];
        }
      } catch (error) {
        this.listaPersonas = [];
        console.log("err", error);
      }
    },


    async getSucursales() {
      //console.log("Sucursales------>", this.usuario);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Sucursal/ListSucursalEmpresa/" + this.usuario.empresa.id,
        });
        //console.log("get sucursales... ", res);
        if (res.length > 0) {
          this.listaSucursales = res;
        } else {
          this.listaSucursales = [];
        }
      } catch (error) {
        this.listaSucursales = [];
        console.log("err", error);
      }
    },

    async getPersonaUbicacion(event, IdPersona) {
      try {
        console.log(`Handling ${event}`);
        //console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/GetByPersona/" + IdPersona,
        });

        let cargo = res.cargo;
        if (cargo) {
          if (event === "Input1") {
            this.InspeccionExtintorSucursal.CargoPersonaInspeccionGSSTId = cargo.id
            this.CargoNombreGSST = cargo.nombre
            this.listaPersonaUbicacion = cargo;

            //console.log("LISTADO DE CARGOS DE LA EMPRESempresasAAAAAAAAAAAAAAAA...", cargo.nombre);
          } else if (event === "Input2") {
            this.InspeccionExtintorSucursal.CargoPersonaInspeccionCOPASSTId = cargo.id
            this.CargoNombreCOPASST = cargo.nombre
            this.listaPersonaUbicacion = cargo;
          }

          else {
            this.InspeccionExtintorSucursal.CargoPersonaInspeccionCOPASSTDosId = cargo.id
            this.CargoNombreCOPASSTDos = cargo.nombre
            this.listaPersonaUbicacion = cargo;
          }


        } else {
          console.log("VACIO", this.listaPersonaUbicacion);
          this.listaPersonaUbicacion = [];
        }



      } catch (error) {
        this.listaPersonaUbicacion = [];
        console.log("err", error);
      }
    },

    DateValidate() {
      this.minDate = this.InspeccionExtintorSucursal.FechaInspeccionExtintor
    },

    async saveDataExtintores() {
      let sucursalInfor = this.InspeccionExtintorSucursal

      try {

        let res = await this.$store.dispatch("hl_post", {
          path: "SstInspeccion/CreateSstInspeccionExtintor/",
          data: sucursalInfor,
        });

        let idSucursalInfo = res.id

        let respuestasExtintor = this.respuestas
        Object.keys(respuestasExtintor).forEach(item => {
          const itemPregunta = respuestasExtintor[item];
          const dataRespuestaExtintor = {
            CodigoExtintor: itemPregunta.CodigoExtintor,
            UbicacionExtintor: itemPregunta.UbicacionExtintor,
            TipoExtintor: itemPregunta.TipoExtintor,
            CapacidadExtintor: itemPregunta.CapacidadExtintor,
            PinturaCilindro: itemPregunta.PinturaCilindro,
            EstadoCilindro: itemPregunta.EstadoCilindro,
            AutoAdhesivo: itemPregunta.AutoAdhesivo,
            ManijaTransporte: itemPregunta.ManijaTransporte,
            ManijaDisparo: itemPregunta.ManijaDisparo,
            Presion: itemPregunta.Presion,
            Manometro: itemPregunta.Manometro,
            Boquilla: itemPregunta.Boquilla,
            Manguera: itemPregunta.Manguera,
            AroSeguridad: itemPregunta.AroSeguridad,
            Corneta: itemPregunta.Corneta,
            Senalizacion: itemPregunta.Senalizacion,
            SoporteColgar: itemPregunta.SoporteColgar,
            AccesoExtintor: itemPregunta.AccesoExtintor,
            FechaRecargaExtintor: itemPregunta.FechaRecargaExtintor,
            FechaProximaRecargaExtintor: itemPregunta.FechaProximaRecargaExtintor,
            ObservacionExtintor: itemPregunta.ObservacionExtintor == "" ? "Sin Observaciones" : itemPregunta.ObservacionExtintor,
            SstInspeccionExtintorId: idSucursalInfo,
          }

          this.$store.dispatch("hl_post", {
            path: "SstInspeccion/CreateSstInspeccionExtintorItems",
            data: dataRespuestaExtintor
          })

          console.log("ESTO LE VOY A PASAR AL POST", dataRespuestaExtintor)

          if (res) {
            Swal.fire({
              title: "Listo!",
              text: "Registro almacenado correctamente",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
              cancelButtonText: "Cancelar",
            }).then(async (result) => {
              if (result.isConfirmed) {
                this.$router.push("/helex/SGSST/InspeccionElementos");
              }
            });
          }



        })







      }
      catch (error) {
        console.log("err", error);
      }
    },
    addRow() {

      this.filas++;

      console.log("filas", this.filas)
    },
    removeRow() {

      if (this.filas <= 1) {

        Swal.fire({
          title: "¡Necesitas al menos un extintor!",
          text: "Necesitas al menos un extintor para la inspección",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        })

        return;

      }
      else {
        this.filas--;
      }
      console.log("filas", this.filas)



    }
  },
  watch: {
    filas() {
      // Llamar al método cuando cambia el valor de filas
      this.initializeInspeccionExtintoresRespuesta();
    }
  }
}
</script>