<template>
  <!-- INSPECCIONAR ELEMENTOS -->
  <card>
    <form @submit.prevent="saveData()" class="p-4">
      <h4 class=""><b> INSPECCIÓN DE ELEMENTOS DE EMERGENCIA</b></h4>
      <div class="row p-4">
        <div class="col-6">
          <label for="sucursal">Sucursal</label><br />
          <b-form-select
            id="sucursal"
            v-model="InspeccionElementosPregunta.sucursalId"
            value-field="id"
            text-field="descripcion"
            :options="listaSucursales"
            required
          ></b-form-select>
        </div>
        <div class="col-6">
          <div>
            <label for="persona" class="form-label">Piso</label>
            <input type="text" list="pisos" v-model="InspeccionElementosPregunta.pisoInspeccion" class="form-control" required />
            <datalist id="pisos">
              <option v-for="pisos in pisoInspeccionFilter" :value="pisos.pisoInspeccion" :key="pisos.pisoInspeccion">
                {{ pisos.pisoInspeccion }}
              </option>
            </datalist>
          </div>
        </div>
        <div class="col-6 py-2">
          <div>
            <label for="evaluador" class="form-label">Evaluador</label>
            <b-form-select id="evaluador" v-model="InspeccionElementosPregunta.PersonaId" value-field="id" text-field="nombres" :options="NombresPersonas" required></b-form-select>
          </div>
        </div>
        <div class="col-6 py-2">
          <div>
            <label for="fecha" class="form-label">Fecha Evaluación</label>
            <input v-model="InspeccionElementosPregunta.FechaInspeccion" placeholder="" type="date" class="form-control" id="fecha" required />
          </div>
        </div>
      </div>

      <div class="text-left p-4">
        <div class="d-flex flex-column align-items-center">
          <h4 class="pb-4"><b> Registro de Equipos de Emergencia</b></h4>

          <table class="table table-striped no-border-top mb-5 col-10" v-for="(preguntas, grupo) in agruparPreguntas" :key="grupo">
            <thead class="h4">
              <tr>
                <th class="col-3">{{ grupo }}</th>
                <th class="col-1">REVISIÓN</th>
                <th class="col-2">OBSERVACIÓN</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="pregunta in preguntas" :key="pregunta.id">
                <td style="text-align: left">
                  {{ pregunta.pregunta }}
                </td>
                <td>
                  <CheckButton class="mb-4 mt-1" v-model="respuestas[pregunta.id].respuesta" />
                </td>
                <td class="d-flex" style="justify-content: center">
                  <textarea type="textarea" class="form-control mb-4" id="description" placeholder="Introduce una descripción" v-model="respuestas[pregunta.id].observacion" />
                </td>
              </tr>
            </tbody>
          </table>

          <!-- <div class="row mt-5 justify-content-center flex-nowrap px-5"
                  v-for="(preguntas, grupo) in agruparPreguntas" :key="grupo">
             
                   
                  <div class="d-flex col-5">
                    <div class="">
                      <h4 for="question" class="form-label pb-3"><b>{{ grupo }}</b></h4>
                      <p class="pb-4" style="font-size: 17px;" v-for="pregunta in preguntas" :key="pregunta.id">{{
                        pregunta.pregunta }}</p>
                    </div>
                  </div>
              
                  <div class="col-1">
                    <div class="mb-3">
                      <label for="true/false" class="form-label d-flex"><b>Revisión</b></label>
                      <div v-for="pregunta in preguntas" :key="pregunta.id">
                        <CheckButton class="mb-4 mt-1" v-model="respuestas[pregunta.id].respuesta" />
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="mb-3">
                      <label for="description" class="form-label"><b>Observación:</b></label>
                      <div v-for="pregunta in preguntas" :key="pregunta.id">
                        <textarea type="textarea" class="form-control mb-4" id="description"
                          placeholder="Introduce una descripción" v-model="respuestas[pregunta.id].observacion" />
                      </div>
                    </div>
                  </div>
                </div> -->
          <!-- Botón de envío -->
          <div class="d-flex justify-content-center">
            <button
              type="submit"
              class="btn btn-primary vs-button vs-button--null vs-button--size-null vs-button--animate vs-button--success vs-button--gradient"
              style="font-size: 15px; height: 50px; padding: 10px"
            >
              <i class="fas fa-plus mr-1"></i> Completar Registro
            </button>
          </div>
        </div>
      </div>
    </form>
  </card>

  <!-- INSPECCIONAR BOTIQUINES -->
</template>
<script>
import { mapGetters } from "vuex";
// import PowerBiIframe from "@/components/helexium/PowerBiIframe.vue";
import CheckButton from "../../../../components/buttons/CheckButton.vue";

const Swal = require("sweetalert2");

export default {
  components: {
    CheckButton,
  },
  name: "sgsstInspeccion",
  data() {
    return {
      paso: "1",

      listaSucursales: [],
      sucursalSeleccionada: "",
      listaPersonas: [],
      personaSeleccionada: "",
      listaPreguntas: [],
      listaPisosElementos: [],

      InspeccionElementosPregunta: {
        FechaInspeccion: null,
        PersonaId: null,
        pisoInspeccion: null,
        sucursalId: null,
      },

      respuestas: {},
    };
  },

  async mounted() {
    this.$isLoading(true);

    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    //console.log("ussssssssssss", this.usuario);

    await this.getSucursales();
    await this.getPersonas();
    await this.getPreguntasElementos();
    await this.getPisos();
    await this.inicializarRespuestas();
    // let idEvaluador = this.InspeccionElementosPregunta.PersonaId
    // console.log("idEvaluadoaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaar", idEvaluador)
    this.$isLoading(false);
    // for (var i = 0; i < 30; i++)
    // {
    //   this.respuestas[i] = {
    //     respuesta: false,
    //     observacion: "",
    //   }
    // }
    //await this.getData();
  },

  computed: {
    ...mapGetters(["userLoggedIn"]),

    agruparPreguntas() {
      return this.listaPreguntas.reduce((acc, item) => {
        if (!acc[item.grupo]) {
          acc[item.grupo] = [];
        }
        acc[item.grupo].push(item);

        //console.log("EL ARREGLO ORDENADO ES", acc)
        return acc;
      }, {});
    },
    NombresPersonas() {
      return this.listaPersonas.map((persona) => ({
        ...persona,
        id: persona.id,
        nombres: `${persona.nombres} ${persona.apellidos}`,
      }));
    },

    pisoInspeccionFilter() {
      const seen = new Set();
      return this.listaPisosElementos.filter((item) => {
        const isDuplicate = seen.has(item.pisoInspeccion);
        seen.add(item.pisoInspeccion);
        return !isDuplicate;
      });
    },
    cambiarEstado() {
      const transformadasEstado = {};

      let respuesta = this.respuestas;

      Object.keys(respuesta).forEach((id) => {
        const item = respuesta[id];

        transformadasEstado[id] = {
          observacion: item.observacion,
          respuesta: item.respuesta ? 1 : 0,
        };
      });
      return transformadasEstado;
    },
  },

  methods: {
    async inicializarRespuestas() {
      for (const grupo of Object.values(this.agruparPreguntas)) {
        for (const pregunta of grupo) {
          await this.$set(this.respuestas, pregunta.id, {
            respuesta: false,
            observacion: "",
          });
        }
      }
    },
    async getPersonas() {
      //console.log("usuario------>", this.usuario);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/ListPersonaByEmpresa/" + this.usuario.empresa.id,
        });
        //console.log("EL ID DE LA EMPRESA ES:::::::::::::::", this.usuario.empresa.id);
        //console.log("get Personas... ", res);
        if (res.length > 0) {
          this.listaPersonas = res;
        } else {
          this.listaPersonas = [];
        }
      } catch (error) {
        this.listaPersonas = [];
        console.log("err", error);
      }
    },

    async getSucursales() {
      //console.log("Sucursales------>", this.usuario);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Sucursal/ListSucursalEmpresa/" + this.usuario.empresa.id,
        });
        //console.log("get sucursales... ", res);
        if (res.length > 0) {
          this.listaSucursales = res;
        } else {
          this.listaSucursales = [];
        }
      } catch (error) {
        this.listaSucursales = [];
        console.log("err", error);
      }
    },

    async getPreguntasElementos() {
      //console.log("usuario------>", this.usuario);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "SstInspeccion/GetSstInspeccionElementosCatalogo/",
        });
        // console.log("get PREGUNTASELEMENTOS... ", res);
        if (res.length > 0) {
          this.listaPreguntas = res;
        } else {
          this.listaPreguntas = [];
        }
      } catch (error) {
        this.listaPreguntas = [];
        console.log("err", error);
      }
    },

    async getPisos() {
      //console.log("pisos------>", this.listaPisosElementos);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "SstInspeccion/GetSstInspeccionElementos/",
        });

        // console.log("get pisoooos... ", res);
        if (res.length > 0) {
          this.listaPisosElementos = res;
        } else {
          this.listaPisosElementos = [];
        }
      } catch (error) {
        this.listaPisosElementos = [];
        console.log("err", error);
      }
    },

    async saveData() {
      //console.log("savedataAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA ", this.InspeccionElementosPregunta);

      /* GUARDAR TODO REGISTRO DE INSPECCIÓN DE ELEMENTOS DE EMERGENCIA */
      try {
        let data = {
          fechaInspeccion: this.InspeccionElementosPregunta.FechaInspeccion,
          personaId: this.InspeccionElementosPregunta.PersonaId,
          pisoInspeccion: this.InspeccionElementosPregunta.pisoInspeccion,
          sucursalId: this.InspeccionElementosPregunta.sucursalId,
        };
        console.log("items ----> ", data);

        let res = await this.$store.dispatch("hl_post", {
          path: "SstInspeccion/CreateSstInspeccionElementos/",
          data: data,
        });
        let IDLastElement = res.id;

        // console.log("items ----> ", res);
        // console.log("items ----> ", IDLastElement);
        // let IDLastElement = 'ID DE LA SUCURSAL'

        let trans = this.cambiarEstado;
        //console.log("itemsaaaaaaaaa ----> ", trans)

        Object.keys(trans).forEach((id) => {
          const itemPregunta = trans[id];

          const dataPreguntas = {
            inspeccionId: IDLastElement,
            catalogoId: id,
            respuesta: itemPregunta.respuesta ? 1 : 0,
            observacion: itemPregunta.observacion == "" ? "Sin observaciones" : itemPregunta.observacion,
          };

          this.$store.dispatch("hl_post", {
            path: "SstInspeccion/CreateSstInspeccionElementosPregunta/",
            data: dataPreguntas,
          });

          //console.log("ESTOS SERÁN LOS ITEMS PARA EL ENDPOINT POST: ", dataPreguntas)
        });

        if (res) {
          Swal.fire({
            title: "Listo!",
            text: "Registro almacenado correctamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$router.push("/helex/SGSST/InspeccionElementos");
            }
          });
        }
      } catch (error) {
        console.log("err", error);
      }
    },
  },
};
</script>

<style scoped>
.container-iframe {
  width: 100%;
  min-height: 70vh;
  position: relative;
}
</style>
