export const BotiquinesItems = [
        {
            item: 'Botiquin',
        },
        {
            item: 'Jabón Quirúrgico',
        },
        {
            item: 'Tijeras',
        },
        {
            item: 'Mascarilla RCP',
        },
        {
            item: 'Guantes',
        },
        {
            item: 'Tapabocas',
        },
        {
            item: 'Jeringas',
        },
        {
            item: 'Curas',
        },
        {
            item: 'Bajalenguas',
        },
        {
            item: 'Venda Triangular',
        },
        {
            item: 'Parche Ocular',
        },
        {
            item: 'Gasa Esterilizada',
        }, 
        {
            item: 'Venda Elástica',
        },
        {
            item: 'Manual Primeros Auxilios',
        },
        {
            item: 'Termómetro digital',
        },
        {
            item: 'Suero Fisiológico (500ml)',
        },
        {
            item: 'Micropore',
        },
        {
            item: 'Esparadrapo',
        },
        {
            item: 'Aplicadores',
        },
        {
            item: 'Algodón',
        },
        {
            item: 'Linterna Dx.',
        }
    
]




